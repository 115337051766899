<template>
  <div class="bg-black-0 rounded p-3 shadow-sm">
    <h2>Daftar Staff</h2>

    <b-row class="mt-3 align-items-end">
      <b-col>
        <p>Semua ({{ staff.total }})</p>
        <b-button variant="primary" to="/dashboards/tambah-staff">
          Tambah Staff
        </b-button>
      </b-col>
      <b-col>
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
              @click="onSearch"
            >
              <i class="ri-search-line remix-icon text-primary"></i>
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            type="search"
            v-model="staff.search"
            placeholder="Search"
            class="border-left-0"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
      class="mt-3"
      :items="staff.items"
      :fields="staff.fields"
      :busy="staff.isBusy"
      responsive
      show-empty
      id="my-table"
    >
      <template #cell(action)="data">
        <b-button
          variant="info"
          size="sm"
          :to="`/dashboards/edit-staff/${data.item.id}`"
        >
          <i class="ri-edit-box-line mr-1"></i>
          Edit
        </b-button>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>

    <b-pagination
      class="p-3"
      align="end"
      v-model="staff.currentPage"
      :total-rows="staff.total"
      :per-page="staff.perPage"
      aria-controls="my-table"
      @change="onPageChange"
    ></b-pagination>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import manageStaffAPI from "../../../../../api/manageStaff/manageStaffAPI";
import manageUserAPI from "../../../../../api/manageuser/manageUserAPI";
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BSpinner,
} from "bootstrap-vue";

const staff = ref({
  items: [],
  fields: [
    { key: "name", label: "Name", sortable: true },
    { key: "email", label: "Email", sortable: true },
    { key: "role", label: "Role", sortable: true },
    { key: "action", label: "Action" },
  ],
  total: 0,
  perPage: 10,
  currentPage: 1,
  search: "",
  isBusy: false,
});

async function fetchStaff() {
  staff.value.isBusy = true;
  const { data: response } = await manageStaffAPI.getAll({
    page: staff.value.currentPage,
    per_page: staff.value.perPage,
    search: staff.value.search,
  });

  staff.value.isBusy = false;
  staff.value.items = response.data.data;
  staff.value.total = response.data.total;
}

function onSearch() {
  staff.value.currentPage = 1;
  fetchStaff();
}

function onPageChange(page) {
  console.log(`Page changed to ${page}`);
  staff.value.currentPage = page;
  fetchStaff();
}

onMounted(() => {
  fetchStaff();
});

watch(
  () => staff.value.search,
  (newSearch, oldSearch) => {
    if (newSearch !== oldSearch) {
      onSearch();
    }
  }
);
</script>
